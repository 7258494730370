import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

function Logout() {
    const {setFreelancer, setToken, setJob} = useContext(AuthContext);

    function logout() {
        setFreelancer({});
        setToken(null);
        setJob({});
        localStorage.setItem('token', '');
    }
    return (
        <button type={"button"} className={"btn btn-danger"} onClick={logout}>Logout</button>
    );
}

export default Logout;