import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {ProgressContext} from "../contexts/ProgressContext";

function Info() {
    const {job, freelancer} = useContext(AuthContext);
    const {progress} = useContext(ProgressContext);

    if (job.qc) {
        return  (
            <div className={"bg-white shadow-sm border p-3 text-center"}>
                <span className={""}>Evaluator: {freelancer.firstName} {freelancer.lastName} -- Job running from {job.publishDate} till {job.dueDate}</span>
            </div>
        );
    }
    else {
        return (
            <div className={"bg-white shadow-sm border p-3 text-center"}>
                <span className={""}>Evaluator: {freelancer.firstName} {freelancer.lastName} -- Job running from {job.publishDate} till {job.dueDate} -- You have done {progress.done}/{progress.total} ({progress.percentage}%). URL count: {progress.urlCount}</span>
            </div>
        );
    }
}

export default Info;