import React, {useState, useContext, useEffect} from "react";
import Error from "./Error";
import {AuthContext} from "../contexts/AuthContext";
import {useHistory} from "react-router-dom";
import {login} from "../models/auth";

function Login() {
    const [email, setEmail] = useState("");
    const [pin, setPin] = useState("");
    const [jobCode, setJobCode] = useState("");
    const [errors, setErrors] = useState([]);
    let history = useHistory();

    const {setFreelancer, setToken, setJob} = useContext(AuthContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('jobCode')) {
            onSubmit(null, urlParams.get('jobCode'));
        }

    }, []);

    async function onSubmit(evt, pJobCode) {
        if (evt) {
            evt.preventDefault();
        }
        setErrors([]);

        try {
            const json =  await login(pJobCode?pJobCode:jobCode);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                const token = json.token;
                localStorage.setItem('token', token);
                setJob(json.job);
                setToken(json.token);
                setFreelancer(json.freelancer);
                history.replace('/job');
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
    }

    return (
        <div className={"w-50 mx-auto bg-white p-3 shadow-sm border mt-5"}>
            <h2>Login to LPA</h2>

            {errors.map((value, index) => {
                return <Error msg={value} key={index}/>;
            })}

            <form onSubmit={onSubmit}>
                <div className={"mb-3 form-floating"}>
                    <input type="text" id={"jobCode"} required={true} className={"form-control"} placeholder={"Job code"} value={jobCode} onChange={(evt) => {
                        setJobCode(evt.target.value);
                    }}/>
                    <label htmlFor="jobCode" className={""}>Job code:</label>
                </div>

                <button type={"submit"} className={"btn btn-success w-100"}>Login</button>
            </form>
        </div>
    );
}

export default Login;
