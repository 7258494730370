const endPoint = process.env.REACT_APP_backend + "/v1";

export async function getMaxPage(token, id) {
    const response = await fetch(endPoint + "/post-processing/" + id + "/maxPage", {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function getRows(token, id, page) {
    const response = await fetch(endPoint + "/post-processing/" + id + "?page=" + page, {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function editQcTick(token, qcTick, id) {
    const response = await fetch(endPoint + "/post-processing/" + id, {
        method: "PUT",
        body: JSON.stringify({
            qcTick
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}

export async function editQcComments(token, comments, id) {
    const response = await fetch(endPoint + "/urls/" + id + "/comments", {
        method: "PUT",
        body: JSON.stringify({
            comments
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}

export async function qcDone(token, id) {
    const response = await fetch(endPoint + "/urls/" + id + "/qcDone", {
        method: "PUT",
        body: JSON.stringify({
            qcDone: 1
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}