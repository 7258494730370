import React, {useContext, useEffect, useState, Fragment} from "react";
import Navbar from "./Navbar";
import Info from "./Info";
import {Link, useHistory, useParams} from "react-router-dom";
import ArrowLeft from "./Icons/ArrowLeft";
import PostProcessingRow from "./PostProcessingRow";
import Pagination from "./Pagination";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import Spinner from "./Spinner";
import {editQcComments, getMaxPage, getRows, qcDone} from "../models/postProcessing";
import {getOne, moveToNextRoot as moveToNextRootModel} from "../models/job";

const endPoint = process.env.REACT_APP_backend + "/v1";

function PostProcessing() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [row, setRow] = useState({});
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [qcComment, setQcComment] = useState('');
    const [qcDoneDisabled, setQcDoneDisabled] = useState(false);

    const {id} = useParams();

    const {token, job} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const json = await getMaxPage(token, id);
                if (json.errors.length > 0) {
                    setErrors(json.errors);
                }
                else {
                    setMaxPage(json.maxPage);
                }
            }
            catch (e) {
                setErrors(["Failed to reach back-end."]);
            }
            try {
                const json = await getOne(token, id);
                if (json.errors.length > 0) {
                    setErrors(json.errors);
                }
                else {
                    setRow(json.row);
                    setQcComment(json.row.qcComment || '');
                    setQcDoneDisabled(json.row.qcDone);
                }
            }
            catch (e) {
                console.error(e);
                setErrors(["Failed to reach back-end."]);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchRows();
        })();
    }, [page]);

    async function fetchRows() {
        setLoading(true);

        try {
            const json = await getRows(token, id, page);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                setRows(json.rows);
            }
        }
        catch (e) {
            setErrors(["Failed to reach back-end."]);
        }

        setLoading(false);
    }

    async function moveToNextRoot() {
        try {
            const json = await moveToNextRootModel(token, id)
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                history.push('/job');
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
    }

    async function changeQcComment() {
        try {
            await editQcComments(token, qcComment, id);
        }
        catch (e) {
            console.error(e);
        }
    }

    async function setQcDone() {
        try {
            await qcDone(token, id);
            setQcDoneDisabled(true);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={"w-50 mx-auto mt-5"}>
            <Navbar />
            <div className={"bg-white border shadow-sm p-3  my-3 "}>
                <div className="row m-0">
                    <div className="col p-0">
                        {!job.qc && <Link to={'/job'} className={"btn btn-secondary btn-sm"}><ArrowLeft /> Back</Link>}


                    </div>
                    <div className="col p-0 text-center">
                        <a href={row.root} target={"_blank"}>{row.root}</a>
                        {job.qc && <Fragment>
                            <textarea className={"form-control mt-2 "} placeholder={"Comments"} value={qcComment} onChange={(evt) => {
                                setQcComment(evt.target.value)
                            }} />
                            <button type={"button"} onClick={changeQcComment} className={"btn mt-2 btn-success"}>Save</button>
                            <button type={"button"} disabled={qcDoneDisabled} onClick={setQcDone} className={"btn btn-success mt-2 ms-2"}>Done</button>
                        </Fragment>}
                    </div>
                    <div className="col p-0">

                    </div>
                </div>


                <hr/>
                {errors.map((value, index) => {
                    return <Error msg={value} key={index}/>;
                })}
                <Pagination page={page} maxPage={maxPage} setPage={setPage} disableMaxPage={!row.done}/>
                <hr/>

                <div className="table-responsive">
                    <table className={"table table-hover"}>
                        <caption className={"caption-top"}>Post-processing list</caption>
                        <thead>
                        <tr className={""}>
                            <th>URL</th>
                            {job.qc ? <th>Should have been collected</th> :  <th>Actions</th>}

                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((value, index) => {
                            return <PostProcessingRow url={value.normalizedUrl} qcTick={value.qcTick} urlId={id} activated={value.activated}  setRows={setRows}  key={value.id} id={value.id} index={index}/>;
                        })}
                        </tbody>
                    </table>
                </div>

                <hr/>
                <Pagination page={page} maxPage={maxPage} setPage={setPage} disableMaxPage={!row.done}/>
                <hr/>

                {(!row.done && !job.qc) && <button className={"btn btn-success w-100"} onClick={moveToNextRoot} disabled={page !== maxPage && rows.length > 0}>Move to next root</button>}
            </div>
            <Info />
        </div>
    );
}

export default PostProcessing;