const endPoint = process.env.REACT_APP_backend + "/v1";

export async function login(jobCode) {
    const response = await fetch(endPoint + "/auth", {
        method: "POST",
        body: JSON.stringify({
            jobCode
        }),
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    });
    return  response.json();
}

export async function getAuth(token) {
    return fetch(endPoint + '/auth', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}
