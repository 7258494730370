import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import Spinner from "./Spinner";
import Error from "./Error";
import {ProgressContext} from "../contexts/ProgressContext";
import {getPreDefinedLabels} from "../models/meta";
import {edit as editModel, remove, submit, getMaxPage as getMaxPageModel, editQcComments} from "../models/job";

const endPoint = process.env.REACT_APP_backend + "/v1";

function ReviewRow({root, page, url, setPage, search, getMaxPage, qcDone, fetchRows, section: sectionFromParent, comments: commentsFromParent, qcComment: qcCommentsFromParent , setRows, index, urlId, jobId}) {
    const [errors, setErrors] = useState([]);
    const [section, setSection] = useState(sectionFromParent.split(','));
    const [sectionNew, setSectionNew] = useState([]);
    const [commentsNew, setCommentsNew] = useState("");
    const [comments, setComments] = useState(commentsFromParent);
    const [qcComments, setQcComments] = useState(qcCommentsFromParent || '');
    const [urlNew, setUrlNew] = useState("");
    const [landingPage, setLandingPage] = useState(sectionFromParent.split(',').includes("hard") || sectionFromParent.split(',').includes("soft"));
    const [labels1, setLabels1] = useState([]);
    const [labels2, setLabels2] = useState([]);
    const [labels3, setLabels3] = useState("");
    const [landingPageNew, setLandingPageNew] = useState(true);
    const [labels1New, setLabels1New] = useState([]);
    const [labels2New, setLabels2New] = useState([]);
    const [labels3New, setLabels3New] = useState("");
    const [loading, setLoading] = useState(false);

    const {getProgress} = useContext(ProgressContext);
    const {token, job} = useContext(AuthContext);

    useEffect(() => {
        (async () => {

        })();

        try {
            const labels = sectionFromParent.split(',');

            setLabels1(labels.filter((value) => {
                return value === "hard" || value === "soft";
            }));
            setLabels2(labels.filter((value) => {
                return value === "video" || value === "audio" || value === "unsure-positive";
            }));
            if (labels.includes("negative")) {
                setLabels3("negative");
            }
            else if (labels.includes("unsure-negative")) {
                setLabels3("unsure-negative");
            }
        }
        catch (e) {
            console.error(e);
        }
        }, []);

    async function edit(evt) {
        evt.preventDefault();
        setLoading(true);
        try {

            const json = await editModel(token, comments, section.toString(), jobId);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                const myModalEl = document.getElementById('modalEdit' + index);
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                setRows((prevState) => {
                    const newState = [...prevState];
                    newState[index].section = section.toString();
                    newState[index].comment = comments;
                    return newState;
                });
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to connect to the back-end."]);
        }
        setLoading(false);
    }

    async function add(evt) {
        evt.preventDefault();
        setLoading(true);
        try {
            const json = await submit(token, urlId, urlNew, commentsNew, sectionNew.toString());
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                const myModalEl = document.getElementById('modal' + index);
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                await getMaxPage();
                await fetchRows();
                await getProgress();
                setUrlNew("");
                setCommentsNew("");
                setLabels1New([]);
                setLabels2New([]);
                setLabels3New("");
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
        setLoading(false);
    }

    async function onDelete(evt) {
        evt.preventDefault();
        setLoading(true);
        try {
            const json = await remove(token, jobId);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                const myModalEl = document.getElementById('deleteModal' + index);
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                const json = await getMaxPageModel(token, search);
                await getProgress();
                setLoading(false);

                if (json.errors.length <= 0) {
                    console.log(json.maxPage, page);
                    if (page >= json.maxPage) {
                        setLoading(false);
                        await getMaxPage();
                        if (page === json.maxPage) {
                            return await fetchRows();
                        }
                        else {
                            return setPage(json.maxPage);
                        }
                    }
                    else {
                        setLoading(false);
                        return await fetchRows();
                    }
                }
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
        setLoading(false);
    }

    async function changeQcComments() {
        try {
            await editQcComments(token, qcComments, jobId);
        }
        catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (landingPage) {
            setLabels3("");
        }
        else {
            setLabels1([]);
            setLabels2([]);
        }
    }, [landingPage]);

    useEffect(() => {
        if (labels3) {
            setSection([...labels1, ...labels2, labels3]);
        }
        else {
            setSection([...labels1, ...labels2]);
        }
    }, [labels1.toString(), labels2.toString(), labels3]);

    useEffect(() => {
        if (landingPageNew) {
            setLabels3New("");
        }
        else {
            setLabels1New([]);
            setLabels2New([]);
        }
    }, [landingPageNew]);

    useEffect(() => {
        if (labels3New) {
            setSectionNew([...labels1New, ...labels2New, labels3New]);
        }
        else {
            setSectionNew([...labels1New, ...labels2New]);
        }
    }, [labels1New.toString(), labels2New.toString(), labels3New]);

    return (
        <tr>
            <td><a href={root} target={"_blank"}>{root}</a> {(job.qc && qcDone) ? "(Root marked Done)": ""}</td>
            <td><a href={url} target={"_blank"}>{url}</a></td>
            <td>{sectionFromParent}</td>
            <td>{commentsFromParent}</td>
            {job.qc && <td><textarea className={"form-control"} value={qcComments} onChange={(evt) => {
                setQcComments(evt.target.value);
            }}/></td>}
            <td>{!job.qc && <button className={"btn btn-success btn-sm"} type={"button"}  disabled={job.qc} data-bs-toggle="modal" data-bs-target={"#modal" + index}>Add</button>}

                <div className="modal fade" id={"modal" + index} tabIndex="-1" aria-labelledby={"modal" + index + "Title"} aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <form onSubmit={add}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"modal" + index + "Title"}>Add url to <a href={root} target={"_blank"}>{root}</a></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    {errors.map((value, index) => {
                                        return <Error msg={value} key={index}/>;
                                    })}
                                    <div className="row m-0 mb-3">
                                        <div className={"form-floating col p-0"}>
                                            <input type="url" id={"url"} required={true} value={urlNew} onChange={(evt) => {
                                                setUrlNew(evt.target.value);
                                            }} className={"form-control"} placeholder={"URL"}/>
                                            <label htmlFor="url" className={""}>URL</label>
                                        </div>
                                        <div className="col">
                                            <input type="checkbox" checked={landingPageNew} onChange={(evt) => {
                                                setLandingPageNew(evt.target.checked);
                                            }}/> Landing page

                                            <div className="row">
                                                <div className="col">
                                                    {/*labels 1*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPageNew} required={landingPageNew} value={labels1New} onChange={(evt) => {
                                                        setLabels1New(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="hard">hard</option>
                                                        <option value="soft">soft</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 2*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPageNew} required={false} value={labels2New} onChange={(evt) => {
                                                        setLabels2New(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="video">video</option>
                                                        <option value="audio">audio</option>
                                                        <option value="unsure-positive">unsure-positive</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 3*/}
                                                    <select className="form-select" multiple={false} required={!landingPageNew} disabled={landingPageNew} value={labels3New} onChange={(evt) => {
                                                        setLabels3New(evt.target.value);
                                                    }}>
                                                        <option value="">Select an option</option>
                                                        <option value="negative">negative</option>
                                                        <option value="unsure-negative">unsure-negative</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mb-3 form-floating"}>
                                    <textarea id={"comment"} className={"form-control"} value={commentsNew} onChange={(evt) => {
                                        setCommentsNew(evt.target.value);
                                    }} placeholder={"Comment"}/>
                                        <label htmlFor="comment" className={""}>Comments</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-success" disabled={loading}>{loading ? <Spinner />: 'Add URL'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={"modalEdit" + index} tabIndex="-1" aria-labelledby={"modalEdit" + index + "Title"} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={edit}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"modalEdit" + index + "Title"}>Edit <a href={url} target={"_blank"}>{url}</a></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    {errors.map((value, index) => {
                                        return <Error msg={value} key={index}/>;
                                    })}
                                    <div className="row m-0 mb-3">
                                        <div className={"form-floating col p-0"}>
                                            <input type="url" id={"url"} required={true} value={url} readOnly={true} className={"form-control"} placeholder={"URL"}/>
                                            <label htmlFor="url" className={""}>URL</label>
                                        </div>
                                        <div className="col">
                                            <input type="checkbox" checked={landingPage} onChange={(evt) => {
                                                setLandingPage(evt.target.checked);
                                            }}/> Landing page

                                            <div className="row">
                                                <div className="col">
                                                    {/*labels 1*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPage} required={landingPage} value={labels1} onChange={(evt) => {
                                                        setLabels1(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="hard">hard</option>
                                                        <option value="soft">soft</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 2*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPage} required={false} value={labels2} onChange={(evt) => {
                                                        setLabels2(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="video">video</option>
                                                        <option value="audio">audio</option>
                                                        <option value="unsure-positive">unsure-positive</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 3*/}
                                                    <select className="form-select" multiple={false} required={!landingPage} disabled={landingPage} value={labels3} onChange={(evt) => {
                                                        setLabels3(evt.target.value);
                                                    }}>
                                                        <option value="">Select an option</option>
                                                        <option value="negative">negative</option>
                                                        <option value="unsure-negative">unsure-negative</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mb-3 form-floating"}>
                                    <textarea id={"comment"} className={"form-control"} value={comments} onChange={(evt) => {
                                        setComments(evt.target.value);
                                    }} placeholder={"Comment"}/>
                                        <label htmlFor="comment" className={""}>Comments</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-success" disabled={loading}>{loading ? <Spinner/>: 'Save changes'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={"deleteModal" + index} tabIndex="-1" aria-labelledby={"deleteModal" + index + "Title"} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={onDelete}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"deleteModal" + index + "Title"}>Delete <a href={url} target={"_blank"}>{url}</a>?</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    {errors.map((value, index) => {
                                        return <Error msg={value} key={index}/>;
                                    })}

                                    <h3>Are you sure you want to delete this URL?</h3>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">No
                                    </button>
                                    <button type="submit" className="btn btn-danger" disabled={loading}>{loading ? <Spinner/>: 'Yes'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {!job.qc && <button className={"btn btn-warning btn-sm ms-1"} type={"button"}  disabled={job.qc} data-bs-toggle="modal" data-bs-target={"#modalEdit" + index}>Edit</button>}

                {job.qc && <button className={"btn btn-sm btn-success"} onClick={changeQcComments}>Save</button>}
                <Link to={"post-processing/" + urlId} className={"btn btn-primary btn-sm ms-1"}>Post-processing</Link>
                {!job.qc && <button className={"btn btn-danger btn-sm ms-1"} type={"button"} disabled={job.qc} data-bs-toggle="modal" data-bs-target={"#deleteModal" + index}>Delete</button>}
            </td>

        </tr>
    );
}

export default ReviewRow;