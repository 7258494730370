const endPoint = process.env.REACT_APP_backend + "/v1";

export async function getPreDefinedLabels(token) {
    const response = await fetch(endPoint + "/meta/preDefinedLabels", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }});

    return response.json();
}

export async function getProgress(token) {
    const response = await fetch(endPoint + "/meta/progress", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}