import React, {useState, useEffect, useContext} from "react";
import Info from "./Info";
import Navbar from "./Navbar";
import {Link, useHistory} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import Spinner from "./Spinner";
import {ProgressContext} from "../contexts/ProgressContext";
import {getPreDefinedLabels} from "../models/meta";
import {getOne, getCount as getCountModel, getNext as getNextModel, submit, moveToNextRoot} from "../models/job";

const endPoint = process.env.REACT_APP_backend + "/v1";

function Job() {
    // const [preDefinedLabels, setPreDefinedLabels] = useState([]);
    const [section, setSection] = useState([]);
    const [root, setRoot] = useState("");
    const [url, setUrl] = useState("");
    const [comments, setComments] = useState("");
    const [errors, setErrors] = useState([]);
    const [id, setId] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [landingPage, setLandingPage] = useState(true);
    const [labels1, setLabels1] = useState([]);
    const [labels2, setLabels2] = useState([]);
    const [labels3, setLabels3] = useState("");

    const {token, job} = useContext(AuthContext);
    const {getProgress} = useContext(ProgressContext);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                /*const json = await getPreDefinedLabels(token);
                if (json.errors.length > 0) {
                    setErrors(json.errors);
                }
                else {
                    setPreDefinedLabels(json.preDefinedLabels);
                }*/
                await getNext();
                await getProgress();
            }
            catch (e) {
                console.error(e);
                setErrors(["Failed to reach back-end."]);
            }
        })();
    }, []);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const json = await getOne(token, id);
                    if (json.errors.length > 0) {
                        setErrors(json.errors);
                    }
                    else if (json.row){
                        setRoot(json.row.root);
                    }
                }
                catch (e) {
                    console.error(e);
                    setErrors(["Failed to reach back-end."]);
                }
                await getCount();
                await getProgress();
            })();
        }
    }, [id]);

    async function getCount() {
        try {
            const json = await getCountModel(token, id);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                setCount(json.count);
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
    }
    async function getNext() {
        try {
            const json = await getNextModel(token);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                if(!json.row || job.qc) {
                    history.push('/review');
                }
                else {
                    setId(json.row.id);
                }
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
    }
    async function onSubmit(evt) {
        setErrors([]);
        evt.preventDefault();
        setLoading(true);

        try {

            const json = await submit(token, id, url, comments, section.toString());
            if (json.errors.length >0) {
                setErrors(json.errors);
            }
            else {
                await getCount();
                await getProgress();
                setUrl("");
                setLabels1([]);
                setLabels2([]);
                setLabels3("");
                setComments("");
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
        setLoading(false);
    }
    async function skip(evt) {
        evt.preventDefault();

        try {

            const json = await moveToNextRoot(token, id, reason);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                const myModalEl = document.getElementById('modalSkip');
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                await getNext();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to connect to the back-end."]);
        }
    }

    useEffect(() => {
        if (landingPage) {
            setLabels3("");
        }
        else {
            setLabels1([]);
            setLabels2([]);
        }
    }, [landingPage]);

    useEffect(() => {
        if (labels3) {
            setSection([...labels1, ...labels2, labels3]);
        }
        else {
            setSection([...labels1, ...labels2]);
        }
    }, [labels1.toString(), labels2.toString(), labels3]);

    return (
        <div className={"w-50 mx-auto mt-5"}>
            <Navbar />
            <div className={"bg-white border shadow-sm p-3  my-3 "}>
                <p className={"text-center"}>ROOT: <a href={root} target={"_blank"}>{root}</a> ({count} submitted for this root)</p>

                {errors.map((value, index) => {
                    return <Error msg={value} key={index}/>;
                })}

                <form onSubmit={onSubmit}>
                    <div className="row m-0 mb-3">
                        <div className={"form-floating col p-0"}>
                            <input type="url" id={"url"} required={true} className={"form-control"} placeholder={"URL"} value={url} onChange={(evt) => {
                                setUrl(evt.target.value);
                            }}/>
                            <label htmlFor="url" className={""}>URL</label>
                        </div>
                        <div className="col">
                            <input type="checkbox" checked={landingPage} onChange={(evt) => {
                                setLandingPage(evt.target.checked);
                            }}/> Landing page

                            <div className="row">
                                <div className="col">
                                    {/*labels 1*/}
                                    <select className="form-select" multiple={true} disabled={!landingPage} required={landingPage} value={labels1} onChange={(evt) => {
                                        setLabels1(Array.from(evt.target.selectedOptions, option => option.value));
                                    }}>
                                        <option value="hard">hard</option>
                                        <option value="soft">soft</option>
                                    </select>
                                </div>
                                <div className="col">
                                    {/*labels 2*/}
                                    <select className="form-select" multiple={true} disabled={!landingPage} required={false} value={labels2} onChange={(evt) => {
                                        setLabels2(Array.from(evt.target.selectedOptions, option => option.value));
                                    }}>
                                        <option value="video">video</option>
                                        <option value="audio">audio</option>
                                        <option value="unsure-positive">unsure-positive</option>
                                    </select>
                                </div>
                                <div className="col">
                                    {/*labels 3*/}
                                    <select className="form-select" multiple={false} required={!landingPage} disabled={landingPage} value={labels3} onChange={(evt) => {
                                        setLabels3(evt.target.value);
                                    }}>
                                        <option value="">Select an option</option>
                                        <option value="negative">negative</option>
                                        <option value="unsure-negative">unsure-negative</option>
                                    </select>
                                </div>
                            </div>
                         {/*   <select className="form-select" multiple={true} required={true} value={section} onChange={(evt) => {
                                setSection(Array.from(evt.target.selectedOptions, option => option.value));
                            }}>
                                {preDefinedLabels.map((value, index) => {
                                    return <option value={value} key={index}>{value}</option>
                                })}
                            </select>*/}
                        </div>
                    </div>

                    <div className={"mb-3 form-floating"}>
                        <textarea id={"comment"} className={"form-control"} placeholder={"Comment"} value={comments} onChange={(evt) => {
                            setComments(evt.target.value);
                        }}/>
                        <label htmlFor="comment" className={""}>Comments</label>
                    </div>

                    <div className="row m-0">
                        <button type={"submit"} disabled={loading} className={"btn btn-success col me-3"}>{loading? <Spinner/>: 'Submit URL'}</button>
                        <button type={"button"} disabled={count > 0} data-bs-toggle="modal" data-bs-target={"#modalSkip"} className={"btn btn-warning col"}>Skip</button>
                        <Link to={"post-processing/" + id} className={"btn btn-primary col ms-3 " + (count <= 0 ? "disabled": "")}>Post-processing</Link>
                    </div>
                </form>
            </div>

            <div className="modal fade" id={"modalSkip"} tabIndex="-1" aria-labelledby={"modalSkipTitle"} aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={skip}>
                            <div className="modal-header">
                                <h5 className="modal-title" id={"modalSkipTitle"}>Skip {root}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className={"mb-3 form-floating"}>
                                    <textarea id={"reason"} required={true} className={"form-control"} placeholder={"Reason"} value={reason} onChange={(evt) => {
                                        setReason(evt.target.value);
                                    }}/>
                                    <label htmlFor="reason" className={""}>Reason</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-bs-dismiss="modal">Close
                                </button>
                                <button type="submit" className="btn btn-success">Skip {root}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Info />
        </div>

    );
}

export default Job;