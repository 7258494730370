const endPoint = process.env.REACT_APP_backend + "/v1";


export async function getOne(token, id) {
    const response = await fetch(endPoint + "/job/" + id, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function getCount(token, id) {
    const response = await fetch(endPoint + "/job/" + id +"/count", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function getNext(token) {
    const response = await fetch(endPoint + "/job", {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function submit (token, urlId, url, comments, section, fromPostPro = false) {
    const response = await fetch(endPoint + "/job", {
        method: "POST",
        body: JSON.stringify({
            urlId,
            url,
            comments,
            section,
            fromPostPro
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}

export async function moveToNextRoot(token, id, reason = null) {
    const response = await fetch(endPoint + "/job/" + id, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            reason
        })
    });
    return response.json();
}

export async function getMaxPage(token, search) {
    const response = await fetch(endPoint + "/job/maxPage?search=" + search, {
        headers: {
            "Authorization": "Bearer " + token
        }
    });

    return response.json();
}

export async function getRows(token, page, search) {
    const response = await fetch(endPoint + "/job/rows?page=" + page + "&search=" + search, {
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}

export async function edit(token, comments, section, jobId) {
    const response = await fetch(endPoint + "/job/" + jobId, {
        method: "PUT",
        body: JSON.stringify({
            comments,
            section
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}

export async function editQcComments(token, comments, jobId) {
    const response = await fetch(endPoint + "/job/" + jobId, {
        method: "PUT",
        body: JSON.stringify({
            comments
        }),
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    });
    return response.json();
}

export async function remove(token, jobId) {
    const response = await fetch(endPoint + "/job/" + jobId, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer " + token
        }
    });
    return response.json();
}