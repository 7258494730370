import React, {useEffect, useState, useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import Spinner from "./Spinner";
import {ProgressContext} from "../contexts/ProgressContext";
import {getPreDefinedLabels} from "../models/meta";
import {submit} from "../models/job";
import {editQcTick} from "../models/postProcessing";

const endPoint = process.env.REACT_APP_backend + "/v1";

function PostProcessingRow({url, activated, id, qcTick: qcTickFromParent, index, urlId, setRows}) {
    const [errors, setErrors] = useState([]);
    const [section, setSection] = useState([]);
    const [comments, setComments] = useState("");
    const [loading, setLoading] = useState(false);
    const [landingPage, setLandingPage] = useState(true);
    const [labels1, setLabels1] = useState([]);
    const [labels2, setLabels2] = useState([]);
    const [labels3, setLabels3] = useState("");
    const [qcTick, setQcTick] = useState(qcTickFromParent);

    const {token, job} = useContext(AuthContext);
    const {getProgress} = useContext(ProgressContext);


    useEffect(() => {
        if (landingPage) {
            setLabels3("");
        }
        else {
            setLabels1([]);
            setLabels2([]);
        }
    }, [landingPage]);

    useEffect(() => {
        if (labels3) {
            setSection([...labels1, ...labels2, labels3]);
        }
        else {
            setSection([...labels1, ...labels2]);
        }
    }, [labels1.toString(), labels2.toString(), labels3]);

    async function changeQcTick(evt) {
        const newQcTick = evt.target.checked;

        try {
            await editQcTick(token, newQcTick, id);
            setQcTick(newQcTick);
        }
        catch (e) {
            console.error(e);
        }
    }
    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);

        try {
            const json = await submit(token, urlId, url, comments, section.toString(), true)
            if (json.errors.length >0) {
                setErrors(json.errors);
            }
            else {
                setRows((prevState) => {
                    const newState =  [...prevState];
                    newState[index].activated = true;
                    return newState;
                });
                const myModalEl = document.getElementById('modal' + index);
                const modal = window.bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                await getProgress();
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to reach back-end."]);
        }
        setLoading(false);
    }

    return (
        <tr className={activated ? "table-success": ""}>
            <td><a href={url} target={"_blank"}>{url}</a></td>
            {job.qc ? <td><input type="checkbox" checked={qcTick} disabled={activated} onChange={changeQcTick}/></td> : (<td><button className={"btn btn-success btn-sm"} disabled={activated || job.qc} type={"button"} data-bs-toggle="modal" data-bs-target={"#modal" + index}>Add</button>
                <div className="modal  fade" id={"modal" + index} tabIndex="-1" aria-labelledby={"modal" + index + "Title"} aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <form onSubmit={onSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"modal" + index + "Title"}>Add <a href={url} target={"_blank"}>{url}</a></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    {errors.map((value, index) => {
                                        return <Error msg={value} key={index}/>;
                                    })}
                                    <div className="row m-0 mb-3">
                                        <div className={"form-floating col p-0"}>
                                            <input type="url" id={"url"} required={true} value={url} readOnly={true} className={"form-control"} placeholder={"URL"}/>
                                            <label htmlFor="url" className={""}>URL</label>
                                        </div>
                                        <div className="col">
                                            <input type="checkbox" checked={landingPage} onChange={(evt) => {
                                                setLandingPage(evt.target.checked);
                                            }}/> Landing page

                                            <div className="row">
                                                <div className="col">
                                                    {/*labels 1*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPage} required={landingPage} value={labels1} onChange={(evt) => {
                                                        setLabels1(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="hard">hard</option>
                                                        <option value="soft">soft</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 2*/}
                                                    <select className="form-select" multiple={true} disabled={!landingPage} required={false} value={labels2} onChange={(evt) => {
                                                        setLabels2(Array.from(evt.target.selectedOptions, option => option.value));
                                                    }}>
                                                        <option value="video">video</option>
                                                        <option value="audio">audio</option>
                                                        <option value="unsure-positive">unsure-positive</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    {/*labels 3*/}
                                                    <select className="form-select" multiple={false} required={!landingPage} disabled={landingPage} value={labels3} onChange={(evt) => {
                                                        setLabels3(evt.target.value);
                                                    }}>
                                                        <option value="">Select an option</option>
                                                        <option value="negative">negative</option>
                                                        <option value="unsure-negative">unsure-negative</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mb-3 form-floating"}>
                                    <textarea id={"comment"} className={"form-control"} placeholder={"Comment"} value={comments} onChange={(evt) => {
                                        setComments(evt.target.value);
                                    }}/>
                                        <label htmlFor="comment" className={""}>Comments</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-success" disabled={loading}>{loading ? <Spinner />: 'Add URL'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </td>)}
        </tr>
    );
}

export default PostProcessingRow;