import React, {useContext, useEffect, useState} from "react";
import Navbar from "./Navbar";
import Info from "./Info";
import Pagination from "./Pagination";
import ReviewRow from "./ReviewRow";
import {AuthContext} from "../contexts/AuthContext";
import Error from "./Error";
import {getMaxPage as getMaxPageModel, getRows} from "../models/job";
import Success from "./Success";
import {ProgressContext} from "../contexts/ProgressContext";


function Review() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [cancelToken, setCancelToken] = useState(null);
    const [cancelTokenMax, setCancelTokenMax] = useState(null);

    const {token, job} = useContext(AuthContext);
    const {progress} = useContext(ProgressContext);

    useEffect(() => {
        if (cancelToken) {
            cancelToken.cancel();
        }

        let newCancelToken = {};


        fetchRows(newCancelToken).catch(err => {
            console.error(err);
        });

        setCancelToken(newCancelToken);
    }, [page, search]);

    useEffect(() => {
        if (cancelTokenMax) {
            cancelTokenMax.cancel();
        }

        let newCancelTokenMax= {};

        getMaxPage(newCancelTokenMax = {}).catch(err => {
            console.error(err);
        });

        setCancelTokenMax(newCancelTokenMax);
    }, [search]);

    async function getMaxPage(newCancelToken = {}) {
        try {
            let canceled = false;
            newCancelToken.cancel = () => {
                canceled = true;
            };
            const json = await getMaxPageModel(token, search);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else {
                if (!canceled) {
                    setMaxPage(json.maxPage);
                }
            }
        }
        catch (e) {
            setErrors(["Failed to reach back-end."]);
        }
    }

    async function fetchRows(newCancelToken = {}) {
        setLoading(true);

        try {
            let canceled = false;
            newCancelToken.cancel = () => {
                canceled = true;
            };

            const json = await getRows(token, page, search);
            if (json.errors.length > 0) {
                setErrors(json.errors);
            }
            else if (!canceled) {
                setRows(json.rows);
            }
        }
        catch (e) {
            setErrors(["Failed to reach back-end."]);
        }

        setLoading(false);
    }

    return (
        <div className={"mx-auto mt-5"} style={{width: '98%'}}>
            <Navbar review={true}/>
            <div className={"bg-white border shadow-sm p-3  my-3 "}>
                {errors.map((value, index) => {
                    return <Error msg={value} key={index}/>;
                })}
                {(progress.done === progress.total && !job.qc) && <Success msg={"You have done all urls, you can deliver your job now."} />}
                <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
                <hr/>
                <input type="text" className={"form-control"} placeholder={"Search"} value={search} onChange={(evt) => {
                    setSearch(evt.target.value);
                }}/>
                <div className="table-responsive">
                    <table className={"table table-hover"}>
                        <caption className={"caption-top"}>Review</caption>
                        <thead>
                        <tr className={""}>
                            <th>Root</th>
                            <th>URL</th>
                            <th>Section</th>
                            <th>{job.qc ? 'FL comments': 'Comments'}</th>
                            {job.qc && <th>QC comments</th>}
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((value, index) => {
                            return <ReviewRow search={search} setPage={setPage} page={page} getMaxPage={getMaxPage} fetchRows={fetchRows} setRows={setRows} root={value.root} jobId={value.id} url={value.url} urlId={value.urlId} comments={value.comment} qcComment={value.qcComment} qcDone={value.qcDone} index={index} section={value.section} key={value.id}/>;
                        })}
                        </tbody>
                    </table>
                </div>
                <hr/>
                <Pagination page={page} maxPage={maxPage} setPage={setPage}/>
            </div>
            <Info />
        </div>
    );
}

export default Review;