import React, {useContext} from "react";
import Logout from "./Logout";
import {Link} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";

function Navbar({review = false}) {
    const {job} = useContext(AuthContext);

    return (
        <div className={"bg-white shadow-sm border p-3 d-flex justify-content-between"}>
            {job.qc && review ? <span></span> : <Link to={review ? '/job': '/review'} className={"btn btn-primary"}>{review ? 'Back' : 'Review'}</Link>}

            <div className={"d-flex align-items-center"}>
                <h4 className={"m-0"}>{job.name}</h4>
            </div>
            <Logout />
        </div>
    );
}

export default Navbar;