import React, {useState, useEffect} from "react";
import Login from "./components/Login";
import Job from "./components/Job";
import PostProcessing from "./components/PostProcessing";
import {AuthContext} from "./contexts/AuthContext";
import {ProgressContext} from "./contexts/ProgressContext";
import  {getProgress as getProgressModel} from "./models/meta";

import {
    Switch,
    Route,
    Redirect,
    useHistory
} from "react-router-dom";
import Review from "./components/Review";
import PrivateRoute from "./components/PrivateRoute";
import {getAuth} from "./models/auth";
import Footer from "./components/Footer";

const endPoint = process.env.REACT_APP_backend + "/v1";

function App() {
    const [freelancer, setFreelancer] = useState({});
    const [token, setToken] = useState(null);
    const [job, setJob] = useState({});
    const [progress, setProgress] = useState({});

    let history = useHistory();


    useEffect(() => {
        (async () => {
            const tokenFromLocalStorage = localStorage.getItem('token');
            if (tokenFromLocalStorage) {
                try {
                    const response = await getAuth(tokenFromLocalStorage);
                    const json = await response.json();

                    if (response.status === 401) {
                        setToken(null);
                        setFreelancer({});
                        setJob({});
                    }
                    if (json.errors.length <= 0 && json.freelancer) {
                        setFreelancer(json.freelancer);
                        setJob(json.job);
                        setToken(tokenFromLocalStorage);
                        history.replace("/job");
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        })();
    }, []);

    async function getProgress() {
        try {
            const json = await getProgressModel(token);
            if (json.errors.length <= 0)  {
                setProgress(json.progress);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <AuthContext.Provider value={{
            freelancer: freelancer,
            token: token,
            setToken: setToken,
            setFreelancer: setFreelancer,
            job: job,
            setJob: setJob
        }}>
            <ProgressContext.Provider value={{
                progress,
                setProgress,
                getProgress
            }}>

                <div className="">
                        <Switch>
                            <Route exact={true} path={'/login'}><Login /></Route>
                            <PrivateRoute exact={true} path={'/job'}><Job /></PrivateRoute>
                            <PrivateRoute exact={true} path={'/post-processing/:id'}><PostProcessing /></PrivateRoute>
                            <PrivateRoute exact={true} path={'/review'}><Review /></PrivateRoute>
                            <Route path={'*'}>
                                <Redirect to={'/login'} />
                            </Route>
                        </Switch>
                </div>
                <Footer />
            </ProgressContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
