import React, {useState, useRef, useEffect} from "react";
import ArrowLeftShort from "./Icons/ArrowLeftShort";
import ArrowRightShort from "./Icons/ArrowRightShort";

function Pagination({maxPage, page, setPage, disableMaxPage = false}) {
    const [pageInput, setPageInput] = useState(page + 1);
    const [focus, setFocus ] = useState(false);
    const pageInputElement = useRef();

    useEffect(() => {
        if (focus) {
            pageInputElement.current.focus();
        }
    }, [focus]);

    return (
        <div className={"d-flex justify-content-between"}>
            <div>
                <button className={"btn btn-outline-primary btn-sm me-1"} disabled={page <= 0} onClick={(evt) => {
                    setPage(0);
                }}><ArrowLeftShort /><ArrowLeftShort /></button>
                <button className={"btn btn-outline-secondary btn-sm"} disabled={page <= 0} onClick={(evt) => {
                    setPage(page - 1);
                }}><ArrowLeftShort /></button>
            </div>
            <div>
                <span><b>Page </b>{focus? <input type={"number"} ref={pageInputElement} value={pageInput} onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                        setPage(parseInt(pageInput) - 1);
                        setFocus(false);
                    }
                }} onChange={(evt) => {
                    if (evt.target.value >= 1 && evt.target.value <= maxPage + 1) {
                        setPageInput(evt.target.value);
                    }
                }} onBlur={(evt) => {
                    setPage(parseInt(pageInput) - 1);
                    setFocus(false);
                }}/>: <span onDoubleClick={(evt) => {
                    if (disableMaxPage) {
                        evt.preventDefault();
                    }
                    else {
                        setFocus(true);
                    }
                }}><b>{page + 1}</b></span>}<b>/{maxPage + 1}</b></span>
            </div>
            <div>
                <button className={"btn btn-outline-secondary btn-sm me-1"} disabled={page >= maxPage} onClick={(evt) => {
                    setPage(page + 1);
                }}><ArrowRightShort /></button>
                <button className={"btn btn-outline-primary btn-sm"} disabled={page >= maxPage || disableMaxPage} onClick={(evt) => {
                    setPage(maxPage);
                }}><ArrowRightShort /><ArrowRightShort /></button>
            </div>
        </div>
    );
}

export default Pagination;